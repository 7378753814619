'use client';

import { Button } from '@features/webshop/shared/ui/Button';
import i18next from 'i18next';

export default function Error({ reset }: { reset: () => void }) {
	return (
		<div className="mx-auto my-4 flex max-w-xl flex-col rounded-lg border border-neutral-200 bg-white p-8 dark:border-neutral-800 dark:bg-black md:p-12">
			<h2 className="text-xl font-bold">Ooops!</h2>
			<p className="my-2">{i18next.t('General error')}</p>
			<Button variant="primary" onClick={() => reset()}>
				{i18next.t('Try again')}
			</Button>
		</div>
	);
}
